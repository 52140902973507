import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Badge, ButtonGroup, Card, Col, Container, Form, Row, Spinner, Stack, Table } from 'react-bootstrap';
import ReactJson from 'react-json-view';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { AnalysisButton, CopyButton, DownloadButton, LinkButton, Tooltiped } from '../Components/CommonButtons';
import { CCard, HTTPHeaders, KVCard, RawPlaylist } from '../Components/CommonCards';
import InputComponent from '../Components/InputComponent';
import { buildQuery, drmIds, httpGet, showPlayButton, useQueryParams } from '../utils';

const MyContext = React.createContext();

function checkStreamsType(children) {
  const types = { video: false, audio: false };
  for (const as of children.filter((c) => c.name === 'AdaptationSet')) {
    if (as.attributes.mimeType) {
      if (as.attributes.mimeType.indexOf('video') !== -1) {
        types.video = true;
      } else if (as.attributes.mimeType.indexOf('audio') !== -1) {
        types.audio = true;
      }
    } else {
      for (const r of as.children.filter((c) => c.name === 'Representation')) {
        if (r.attributes.mimeType.indexOf('video') !== -1) {
          types.video = true;
        } else if (r.attributes.mimeType.indexOf('audio') !== -1) {
          types.audio = true;
        }
      }
    }
  }
  return types;
}

function Header() {
  const { search } = useLocation();
  const { media, asset } = useQueryParams();

  return (
    <Card border='success'>
      <Card.Header>
        DASH MPD - <b className='text-primary'>{asset}</b>
      </Card.Header>
      <Card.Body>
        <Stack direction='horizontal' gap='1'>
          <InputComponent text='MPD URL'>
            <Form.Control value={media} readOnly />
          </InputComponent>
          <ButtonGroup>
            <Tooltiped text='play stream'>
              <LinkButton to={`/player${search}`} disabled={!showPlayButton(media)}>
                <i className='fas fa-play' />
              </LinkButton>
            </Tooltiped>
            <DownloadButton href={media} />
            <CopyButton text={media} size='md' />
          </ButtonGroup>
        </Stack>
      </Card.Body>
    </Card>
  );
}

// nice name
// just bg light and text dark everywhere
// add props and children if needed...
function MyBadge(props) {
  return <Badge bg='light' text='dark' {...props} />;
}

function elemLayout(child, { media, format, DRMServiceId, licenseVersion, asset }) {
  switch (child.name) {
    case 'MPD':
      return {
        color: 'info',
        open: true,
        header: <MyBadge>{child.attributes.type}</MyBadge>,
      };
    case 'Period': {
      currentPeriod = child;
      const streams = checkStreamsType(child.children);
      return {
        color: 'success',
        header: (
          <>
            <MyBadge>{child.attributes.id}</MyBadge>
            <MyBadge>{child.attributes.start}</MyBadge>
            {Object.entries(streams)
              .filter(([_, v]) => !v)
              .map(([k, _]) => (
                <Badge key={k} bg='danger' text='white'>
                  {k.toUpperCase()} STREAM MISSING
                </Badge>
              ))}
          </>
        ),
      };
    }
    case 'AdaptationSet':
      currentAdaptationSet = child;
      return {
        color: 'primary',
        header: (
          <>
            <MyBadge>{child.attributes.contentType}</MyBadge>
            <MyBadge>{child.attributes.mimeType}</MyBadge>
            {child.attributes.group && <MyBadge>Group {child.attributes.group}</MyBadge>}
            <MyBadge>{child.children.filter((c) => c.name === 'Representation').length} Representation</MyBadge>
            <MyBadge>{child.children.filter((c) => c.name === 'ContentProtection').length} ContentProtection</MyBadge>
          </>
        ),
      };
    case 'BaseURL':
      return { color: 'dark' };
    case 'ContentProtection':
      return {
        color: 'success',
        header: <MyBadge>{drmIds[child.attributes.schemeIdUri.toLowerCase()]}</MyBadge>,
      };
    case 'Role':
      return {
        color: 'secondary',
        header: (
          <>
            <MyBadge>{child.attributes.schemeIdUri}</MyBadge>
            <MyBadge>{child.attributes.value}</MyBadge>
          </>
        ),
      };
    case 'SegmentTemplate':
      return {
        color: 'warning',
        header: <MyBadge>{child.attributes.media}</MyBadge>,
      };
    case 'Representation': {
      const disabled = !!child.children.find((c) => c.name === 'SegmentBase');
      return {
        color: 'info',
        header: (
          <>
            <MyBadge>{child.attributes.id}</MyBadge>
            <MyBadge>{child.attributes.bandwidth / 1e3} kbps</MyBadge>
          </>
        ),
        rightHeader: (
          <AnalysisButton
            className={clsx('float-end', disabled && 'disabled')}
            variant='dark'
            size='sm'
            as={Link}
            to={`/mpd/chunks?${buildQuery({
              mpd: media,
              format,
              DRMServiceId,
              licenseVersion,
              period: currentPeriod.attributes.id,
              adaptationset: currentAdaptationSet.attributes.id,
              representation: child.attributes.id,
              asset,
            })}`}>
            Chunks list
          </AnalysisButton>
        ),
      };
    }
    case 'AudioChannelConfiguration':
      return {
        color: 'secondary',
        header: (
          <>
            <MyBadge>{child.attributes.schemeIdUri}</MyBadge>
            <MyBadge>{child.attributes.value}</MyBadge>
          </>
        ),
      };
    case 'Event':
      return {
        color: 'secondary',
        header: (
          <>
            <MyBadge>{child.attributes.id}</MyBadge>
            <MyBadge>{child.attributes.duration}</MyBadge>
            <MyBadge>{child.attributes.presentationTime}</MyBadge>
          </>
        ),
      };
    case 'EventStream':
      return {
        color: 'secondary',
        header: (
          <>
            <MyBadge>{child.attributes.schemeIdUri}</MyBadge>
            <MyBadge>{child.attributes.timescale}</MyBadge>
          </>
        ),
      };
    case 'Tracking':
      return {
        color: 'secondary',
        header: <MyBadge>{child.attributes.event}</MyBadge>,
      };
    case 'SegmentBase':
      return {
        color: 'warning',
        header: <MyBadge>{child.attributes.indexRange}</MyBadge>,
      };
    case 'Binary':
      return {
        color: 'info',
        header: (
          <>
            <MyBadge text='success'>{currentPeriod.attributes.start}</MyBadge>
            {Object.entries(child.attributes)
              .filter(([k, _]) => k.startsWith('segmentation_message_'))
              .map(([k, v]) => (
                <MyBadge key={k}>{v}</MyBadge>
              ))}
          </>
        ),
      };
    default:
      return { color: 'light' };
  }
}

function MpdElement({ child }) {
  const query = useQueryParams();
  const { filter, expand } = useContext(MyContext);
  const { color, header, rightHeader, open } = elemLayout(child, query);

  function walk() {
    return child.children.map((c, i) => <MpdElement child={c} key={i} />);
  }

  return (
    <>
      {filter.test(child.name) ? (
        <CCard
          open={open || expand}
          title={child.name}
          header={header}
          rightHeader={rightHeader}
          color={color}
          border={color === 'light' ? 'dark' : color}>
          <Stack gap='1'>
            {Object.keys(child.attributes).length > 0 && (
              <Table size='sm' hover>
                <tbody>
                  {Object.entries(child.attributes).map(([k, v]) => (
                    <tr key={k}>
                      <td>
                        <b>{k}</b>
                      </td>
                      <td>{v}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {child.text && <span>{child.text}</span>}
            {child.json && (
              <ReactJson src={child.json} collapsed={1} displayDataTypes={false} theme='monokai' style={{ fontSize: 'medium' }} />
            )}
            {walk()}
          </Stack>
        </CCard>
      ) : (
        walk()
      )}
    </>
  );
}

let currentPeriod, currentAdaptationSet;

function Main() {
  currentAdaptationSet = null;
  currentPeriod = null;
  const { media } = useQueryParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [resp, setResp] = useState(null);
  const [expand, setExpand] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const debounced = useDebouncedCallback((filter) => {
    searchParams.set('filter', filter);
    setSearchParams(searchParams);
  }, 500);

  useEffect(() => {
    httpGet(`/api/dash/mpd?mpd=${encodeURIComponent(media)}`, setResp, setError, () => setLoading(false));
  }, [media]);

  let filter;
  try {
    filter = new RegExp(searchParams.get('filter') ?? '', 'i');
  } catch {
    // impossible regex
    filter = /(?!x)x/;
  }

  return (
    <Container>
      <Stack gap='2'>
        <Header />
        {loading && <Spinner variant='primary' />}
        {error && <Alert variant='danger'>{error}</Alert>}
        {resp && (
          <>
            <HTTPHeaders headers={resp.headers} />
            <RawPlaylist url={media} />
            <KVCard title='Namespaces' border='dark' obj={resp.data.namespaces} open />
            <Row>
              <Col>
                <InputComponent text='Filter' icon='fas fa-filter'>
                  <Form.Control
                    defaultValue={searchParams.get('filter') ?? ''}
                    onChange={(evt) => debounced(evt.target.value)}
                    placeholder='regex to filter tags'
                  />
                </InputComponent>
              </Col>
              <Col md='auto' className='d-flex align-items-center'>
                <Form.Check type='switch' label='Expand/Collapse all' checked={expand} onChange={() => setExpand(!expand)} />
              </Col>
            </Row>
            <MyContext.Provider value={{ filter, expand }}>
              <MpdElement child={resp.data.children} />
            </MyContext.Provider>
          </>
        )}
      </Stack>
    </Container>
  );
}

export default Main;
