import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Alert, Badge, Card, Collapse, Spinner, Stack, Table } from 'react-bootstrap';

import { allColumns, httpGet } from '../utils';
import { CopyButton } from './CommonButtons';

// simple collapsable card with optionnaly decorated header
export function CCard({ title, children, header, rightHeader, border, onClick, color = 'light', open = false, body = true, ...props }) {
  const [isOpen, setOpen] = useState(open);

  useEffect(() => setOpen(open), [open]);

  function onClick2() {
    if (onClick) {
      onClick(!isOpen);
    }
    setOpen(!isOpen);
  }

  return (
    <Card {...props} border={border ? border : color === 'light' ? null : color}>
      <Card.Header className={`bg-${color}`}>
        <button
          className={clsx('link-button', ['warning', 'light', 'info'].includes(color) ? 'text-dark' : 'text-white')}
          onClick={onClick2}>
          <Stack direction='horizontal' gap='2'>
            {title}
            {header}
            <i className={clsx('fas', isOpen ? 'fa-chevron-down' : 'fa-chevron-right')} />
          </Stack>
        </button>
        {rightHeader}
      </Card.Header>
      <Collapse in={isOpen}>
        <div>{body ? <Card.Body>{children}</Card.Body> : children}</div>
      </Collapse>
    </Card>
  );
}

export function HTTPHeaders({ headers, ...props }) {
  return <KVCard obj={headers} {...props} title='HTTP headers' />;
}

// Key-Value Card that takes a one dimensional object as input and displays it in a Table
export function KVCard({ obj, ...props }) {
  if (obj == null || Object.keys(obj).length === 0) {
    return null;
  }

  return (
    <CCard {...props}>
      <Table size='sm' hover>
        <tbody>
          {Object.entries(obj).map(([k, v]) => (
            <tr key={k}>
              <td>
                <b>{k}</b>
              </td>
              <td>{v}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </CCard>
  );
}

export function TableCard({ objects, hiddenColumns = [], ...props }) {
  if (objects.length === 0) {
    return null;
  }

  const columns = allColumns(objects).filter((k) => !hiddenColumns.includes(k));

  return (
    <CCard
      {...props}
      rightHeader={
        <Badge bg='primary' className='float-end'>
          {objects.length}
        </Badge>
      }>
      <Table size='sm' hover responsive>
        <thead>
          <tr>
            {columns.map((k) => (
              <th key={k}>{k}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {objects.map((sk, i) => (
            <tr key={i}>
              {columns.map((k) => (
                <td key={k}>{sk[k]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </CCard>
  );
}

export function RawPlaylist({ url, ...props }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [raw, setRaw] = useState(null);

  function onClick(open) {
    if (raw === null && open) {
      setError(null);
      setLoading(true);
      httpGet(url, setRaw, setError, () => setLoading(false));
    }
  }

  return (
    <CCard title='Raw playlist' {...props} onClick={onClick} rightHeader={raw && <CopyButton className='float-end' size='sm' text={raw} />}>
      {loading && <Spinner variant='primary' />}
      {error && <Alert variant='danger'>{error}</Alert>}
      {raw && <pre style={{ maxHeight: '500px' }}>{raw}</pre>}
    </CCard>
  );
}

export default CCard;
