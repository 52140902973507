import React, { Component } from 'react';
import { Badge, ButtonGroup, Card, Col, Form, ListGroup, ProgressBar, Ratio, Row, Stack } from 'react-bootstrap';
import RxPlayer from 'rx-player';

import { BadgeCounter, Tooltiped, TooltipedButton } from '../Components/CommonButtons';
import InputComponent from '../Components/InputComponent';
import PlayerLogsCanvas from '../Components/PlayerLogsCanvas';
import ctx from '../context';
import {
  arrayToString,
  drmSystemInfo,
  dtf,
  getFormatFromFamily,
  httpGet,
  S2TimeString,
  ToggleFullScreen,
  VIDEO_ELEMENT_ID,
} from '../utils';

const DEFAULT_SUBTITLE = 'fra';
const DEFAULT_AUDIO_TRACK = 'fra';
const DEFAULT_ZAP_TIMER_INTERVAL = 15; // 15 s
const TIMER_TICK_VALUE = 1e3; //1s
const MAX_LOG_ITEM = 20;

const statusStyle = {
  unknow: {
    style: 'light',
    icon: 'fas fa-question',
    message: 'Channel status unknow for the moment',
  },
  ko: {
    style: 'danger',
    icon: 'fas fa-thumbs-down',
    message: 'A problem is detected on this channel',
  },
  ok: {
    style: 'success',
    icon: 'fas fa-thumbs-up',
    message: 'This channel work fine',
  },
};

const autoZapButtonStyle = {
  true: 'far fa-stop-circle',
  false: 'far fa-play-circle',
};

function setPlayerSeek(player, seekValue) {
  const pos = player.getPosition() + seekValue;
  const min = player.getMinimumPosition();
  const max = player.getMaximumPosition();

  player.seekTo({
    position: pos < min ? min : pos > max ? max : pos,
  });
}

function ChannelItem(props) {
  const channel = props.channel;
  const active = props.active;
  const itemStyle = active ? null : statusStyle[channel.status].style;
  const iconStyle = active ? 'fas fa-play' : statusStyle[channel.status].icon;
  const message = statusStyle[channel.status].message;

  return (
    <Tooltiped text={message}>
      <ListGroup.Item action variant={itemStyle} active={props.active} onClick={props.callback}>
        <i className={iconStyle} /> {channel.asset.toUpperCase()}
      </ListGroup.Item>
    </Tooltiped>
  );
}

function ChannelListComponent(props) {
  if (props.channels.length === 0) {
    return null;
  }
  return (
    <ListGroup variant='flush' size='sm' style={{ maxHeight: '800px', overflow: 'auto' }}>
      {props.channels.map((ch, index) => (
        <ChannelItem key={ch.asset} channel={ch} active={index === props.currentIndex} callback={() => props.callback(index)} />
      ))}
    </ListGroup>
  );
}

export class MonitoringPlayer extends Component {
  static contextType = ctx;
  constructor(props) {
    super(props);
    this.state = {
      isCertLoaded: false,
      isConfLoaded: false,
      playerState: 'UNKNOW',
      liveOffset: '00:00:00',
      seekValue: 100,
      currentBitrate: 0,
      asset: '',
      url: '',
      autozap: true,
      zapTimerInterval: DEFAULT_ZAP_TIMER_INTERVAL,
      logs: [],
      timerCounter: DEFAULT_ZAP_TIMER_INTERVAL,
      showLogs: false,
      isMute: false,
    };
    this.player = null;
    this.queryParams = Object.fromEntries(new URLSearchParams(window.location.search));
    this.format = getFormatFromFamily(this.queryParams.family);
    this.isHls = this.format === 'hls';

    Object.getOwnPropertyNames(MonitoringPlayer.prototype).map((f) => (this[f] = this[f].bind(this)));
  }

  printLog(msg, style = 'text-muted') {
    this.setState(({ logs }) => {
      const length = logs.unshift({
        text: `[${dtf.format(Date.now())}] ${msg}`,
        style,
        msg,
      });
      //remove first elt of the array
      if (length > MAX_LOG_ITEM) {
        logs.pop();
      }
      if (style === 'text-danger') {
        console.error(msg);
      }
      return { logs };
    });
  }

  async getLicense(challenge, contentId, drm, drmserviceid, licenseVersion) {
    let licenseUri = this.context.licenseServerUri.get(licenseVersion) ?? this.context.licenseServerUri.get('2');
    licenseUri = this.queryParams.license ?? `${licenseUri}/${drm}`;

    const qsParams = {};
    if (contentId !== undefined) {
      const id = contentId.split('/');
      qsParams.rule = id[id.length - 1];
    }
    qsParams.serviceId = drmserviceid;
    licenseUri += `?${Object.entries(qsParams)
      .map(([k, v]) => `${k}=${v}`)
      .join('&')}`;
    const opts = {
      method: 'POST',
      body: challenge,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    };

    try {
      const response = await fetch(licenseUri, opts);
      if (response.ok) {
        const buffer = await response.arrayBuffer();
        return drm !== 'FairPlay' ? buffer : new Uint8Array(buffer);
      } else {
        return Promise.reject({
          noRetry: true,
          fallbackOnLastTry: true,
          message: `getLicense error ${response.statusText}${await response.text()}`,
        });
      }
    } catch (error) {
      return Promise.reject({
        noRetry: true,
        fallbackOnLastTry: true,
        message: `getLicense fetch error ${error.message}`,
      });
    }
  }

  getConfiguration() {
    httpGet(
      `/api/monitoring/config${window.location.search}`,
      (body) => {
        if (body.channels.length === 0) {
          this.printLog('get Configuration error : No channel found ', 'text-danger');
        } else {
          const d = {};
          for (const c of this.state.channelList) {
            d[c.asset] = c.status;
          }
          for (const c of body.channels) {
            c.status = d[c.asset] ?? 'unknow';
          }
          this.setState({ channelList: body.channels }, this.startPlayer);
        }
      },
      (err) => {
        this.printLog(`get Configuration fetch error ${err}`, 'text-danger');
      }
    );
  }

  async componentDidMount() {
    const _self = this;
    const videoElt = document.getElementById(VIDEO_ELEMENT_ID);

    //Set Player ans certificat url
    let serverCertificateUrl = '';
    if (this.isHls) {
      serverCertificateUrl = 'https://secure-webtv-static.canal-plus.com/bourbon/cert/fps-mycanal-1-20161208.der';
    } else {
      serverCertificateUrl = 'https://secure-webtv-static.canal-plus.com/widevine/cert/cert_license_widevine_com.bin';
    }
    this.player = new RxPlayer({
      videoElement: videoElt,
      preferredAudioTracks: [{ language: DEFAULT_AUDIO_TRACK, audioDescription: false }],
      preferredTextTracks: [{ language: DEFAULT_SUBTITLE, closedCaption: false }],
    });

    //Get Certificate
    try {
      const respCertificat = await fetch(serverCertificateUrl);
      if (!respCertificat.ok) {
        throw new TypeError(`getcertificat error ${respCertificat.statusText}`);
      }
      this.certificat = await respCertificat.arrayBuffer();
      this.setState({ isCertLoaded: true });
      this.startPlayer();
    } catch (error) {
      this.printLog(`getcertificat fetch error ${error.message}`, 'text-danger');
    }

    //get Configuration file
    const configurationUrl = `/api/monitoring/config${window.location.search}`;

    try {
      const respConf = await fetch(configurationUrl, { credentials: 'include' });

      if (!respConf.ok) {
        throw new TypeError(`Get Configuration error  ${respConf.statusText}`);
      }
      const configuration = await respConf.json();
      if (configuration.channels.length === 0) {
        this.printLog('get Configuration error : No channel found ', 'text-danger');
        return;
      }

      configuration.channels.forEach((element) => {
        element.status = 'unknow';
      });
      this.setState(
        {
          isConfLoaded: true,
          channelList: configuration.channels,
        },
        this.startPlayer
      );
    } catch (error) {
      this.printLog(`get Configuration fetch error ${error.message}`, 'text-danger');
    }

    //add different eventListener
    this.player.addEventListener('error', (error) => {
      const text = `[${_self.state.asset}] ${error.message} fatal=${error.fatal ? 'YES' : 'NO'}`;
      _self.printLog(text, 'text-danger');

      _self.setState((prevState) => {
        const newChannelList = prevState.channelList;
        newChannelList[prevState.assetIndex].status = 'ko';
        return { channelList: newChannelList };
      });

      if (_self.state.autozap) {
        _self.stopZappingTimer();
        _self.nextZapping();
        _self.startZappingTimer();
      }
    });

    this.player.addEventListener('playerStateChange', this.handlePlayerStateChange);
    this.player.addEventListener('videoRepresentationChange', this.handleVideoRepresentationChange);

    //DRM Signalisation listener
    videoElt.addEventListener('webkitneedkey', (evt) => {
      this.xSessionKeyUri = arrayToString(evt.initData).split('skd://')[1];
    });
    // logs panel
    document.addEventListener('keydown', this.onKeyDown);
  }

  onKeyDown({ target, key }) {
    if (target.nodeName === 'INPUT') {
      return;
    }
    switch (key) {
      case 'l':
      case ' ':
        this.setState({ showLogs: !this.state.showLogs });
        break;
      case 'p':
        this.player.getPlayerState() === 'PLAYING' ? this.player.pause() : this.player.play();
        break;
      case 'm':
        this.player.isMute() ? this.player.unMute() : this.player.mute();
        this.setState({ isMute: this.player.isMute() });
        break;
      default:
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
    this.stopZappingTimer();
    this.player.dispose();
  }

  timer() {
    if (this.state.timerCounter === 0) {
      this.nextZapping();
      this.setState({ timerCounter: this.state.zapTimerInterval });
    } else {
      this.setState((prevState) => ({
        timerCounter: prevState.timerCounter - 1,
      }));
    }
  }

  startZappingTimer() {
    this.setState({ timerCounter: this.state.zapTimerInterval });
    this.intervalHandle = setInterval(this.timer, TIMER_TICK_VALUE);
  }

  stopZappingTimer() {
    clearInterval(this.intervalHandle);
  }

  nextZapping() {
    const newAssetIndex = this.state.assetIndex + 1 < this.state.channelList.length ? this.state.assetIndex + 1 : 0;
    if (newAssetIndex === 0) {
      this.stopZappingTimer();
      this.getConfiguration();
    } else {
      this.setState((prevState) => {
        const newChannelList = prevState.channelList;
        newChannelList[newAssetIndex].status = 'unknow';
        return { channelList: newChannelList, assetIndex: newAssetIndex, asset: newChannelList[newAssetIndex].asset };
      });
      this.loadVideo(this.state.channelList[newAssetIndex]);
    }
  }

  startPlayer() {
    if (!this.state.isConfLoaded || !this.state.isCertLoaded) {
      return;
    }
    this.printLog("let's go !");

    //first zapping
    this.setState({
      asset: this.state.channelList[0].asset,
      assetIndex: 0,
    });
    this.loadVideo(this.state.channelList[0]);

    //next zapping
    this.startZappingTimer();
  }

  loadVideo(elt) {
    const options = {
      url: elt.url,
      transport: this.format,
      autoPlay: true,
      textTrackMode: 'html',
      textTrackElement: document.getElementById('SUBTITLE_ID_ELT'),
    };

    const _self = this;

    if (!this.isHls) {
      //Manage Key Systems option
      const fallbackOnValue = {
        onKeyInternalError: 'fallback',
        onKeyOutputRestricted: 'fallback',
      };
      options.manualBitrateSwitchingMode = 'direct';
      options.lowLatencyMode = false;
      options.transportOptions = { aggressiveMode: false };

      options.keySystems = [];
      Object.values(drmSystemInfo).forEach((drm) => {
        const obj = {
          type: drm.domain,
          getLicense: (challenge) => _self.getLicense(challenge, undefined, drm.name, elt.drmserviceid, elt.licenseversion),
          ...fallbackOnValue,
          serverCertificate: drm.name === 'Widevine' ? _self.certificat : undefined,
        };
        options.keySystems.push(obj);
      });
    } else {
      //HLS
      options.transport = 'directfile';
      options.keySystems = [
        {
          type: 'fairplay',
          getLicense: (challenge) => _self.getLicense(challenge, this.xSessionKeyUri, 'FairPlay', elt.drmserviceid, elt.licenseversion),
          serverCertificate: this.certificat,
        },
      ];
      options.preferredAudioTracks = [{ language: DEFAULT_AUDIO_TRACK, audioDescription: false }];
      options.preferredTextTracks = [{ language: DEFAULT_SUBTITLE, closedCaption: false }];
    }

    this.player.loadVideo(options);
  }

  displayPlayerPosition() {
    this.setState({
      liveOffset: S2TimeString(this.player.getMaximumPosition() - this.player.getWallClockTime()),
    });
  }

  //Handle PLAYER EVENT
  handlePlayerStateChange(state) {
    this.setState({ playerState: state });

    if (state === 'PLAYING') {
      if (!this.isHls) {
        this.displayPlayerPosition();
        this.updateSeekBarPosition();
      }

      this.setState(function (prevState) {
        const newChannelList = prevState.channelList;
        newChannelList[prevState.assetIndex].status = 'ok';
        return { channelList: newChannelList };
      });
    }
  }

  handleVideoRepresentationChange(r) {
    this.setState({ currentBitrate: r.bitrate });
  }

  //HANDLE USER CONTROL
  handleControlButton(id) {
    const player = this.player;
    if (player.getPlayerState() === 'STOPPED') {
      return;
    }

    switch (id) {
      case 'playButton':
        if (player.getPlayerState() === 'PLAYING') {
          player.pause();
        } else {
          player.play();
        }
        break;
      case 'seekMinButton':
        player.seekTo({ position: player.getMinimumPosition() + 5 });
        break;
      case 'seekLiveButton':
        player.seekTo({ position: player.getMaximumPosition() - 5 });
        break;
      case 'backwardButton':
        setPlayerSeek(player, -30);
        break;
      case 'forwardButton':
        setPlayerSeek(player, 30);
        break;
      case 'fastBackwardButton':
        setPlayerSeek(player, -300);
        break;
      case 'fastForwardButton':
        setPlayerSeek(player, 300);
        break;
      case 'Muting':
        if (player.isMute()) {
          player.unMute();
        } else {
          player.mute();
        }
        break;
      default:
      //nothing to do;
    }
  }

  handleSeekChange(e) {
    const min = this.player.getMinimumPosition();
    const max = this.player.getMaximumPosition();
    const delta = Math.round((max - min) * (1 - e.target.value / 100));

    this.setState({
      seekValue: e.target.value,
      liveOffset: S2TimeString(delta),
    });
  }

  handleSeekUp(e) {
    const min = this.player.getMinimumPosition();
    const max = this.player.getMaximumPosition();

    this.player.seekTo({
      position: Math.round(min + (max - min) * (e.target.value / 100)),
    });
  }

  updateSeekBarPosition() {
    const player = this.player;
    const min = player.getMinimumPosition();
    const max = player.getMaximumPosition();

    this.setState({
      seekValue: Math.round(((player.getPosition() - min) * 100) / (max - min)),
    });
  }

  handleAutoZapButton() {
    const zap = !this.state.autozap;
    if (zap) {
      this.nextZapping();
      this.startZappingTimer();
    } else {
      this.stopZappingTimer();
    }

    this.setState({
      autozap: zap,
    });
  }

  handleTimerChange(e) {
    this.setState({ zapTimerInterval: e.target.value });
  }

  getNbChannelStatus(filterValue) {
    return this.state.channelList.filter((elt) => elt.status === filterValue).length;
  }

  onChannelClick(index) {
    this.stopZappingTimer();

    this.setState((prevState) => {
      const newChannelList = prevState.channelList;
      newChannelList[index].status = 'unknow';
      return { channelList: newChannelList, autozap: false, assetIndex: index, asset: this.state.channelList[index].asset };
    });

    this.loadVideo(this.state.channelList[index]);
  }

  render() {
    return (
      <Row>
        <Col md={8}>
          <Card border='dark'>
            <Card.Header>
              <h5 className='mb-0'>
                <Stack direction='horizontal' gap='1'>
                  <Badge>{this.queryParams.config.toUpperCase()}</Badge>
                  <Badge>{this.queryParams.stream}</Badge>
                  <Badge>{this.queryParams.filter}</Badge>
                  <Badge>{this.format}</Badge>
                  <Badge>{this.state.asset}</Badge>
                  <Badge className='ms-auto'>Player {this.state.playerState}</Badge>
                  {!this.isHls && <Badge>{this.state.currentBitrate / 1e3} kbps</Badge>}
                </Stack>
              </h5>
            </Card.Header>
            <Card.Body className='p-0'>
              <Ratio aspectRatio='16x9'>
                <>
                  <video controls={this.isHls} id={VIDEO_ELEMENT_ID} />
                  <div id='SUBTITLE_ID_ELT' />
                </>
              </Ratio>
            </Card.Body>
            {!this.isHls && (
              <Card.Footer>
                <Stack gap='3' className='mt-2'>
                  <input
                    type='range'
                    min='0'
                    max='100'
                    step='0.01'
                    value={this.state.seekValue}
                    onChange={this.handleSeekChange}
                    onMouseUp={this.handleSeekUp}
                  />
                  <Stack direction='horizontal'>
                    <Col md='3'>
                      <InputComponent text='Live offset'>
                        <Form.Control readOnly value={this.state.liveOffset} />
                      </InputComponent>
                    </Col>
                    <PlayerLogsCanvas
                      className='ms-auto'
                      showLogs={this.state.showLogs}
                      onClick={() => this.setState({ showLogs: true })}
                      onHide={() => this.setState({ showLogs: false })}
                      player={this.player}
                      logs={this.state.logs}
                    />
                  </Stack>
                  <div className='mx-auto'>
                    <Stack direction='horizontal' gap='2'>
                      <ButtonGroup>
                        <TooltipedButton text='Buffer start' onClick={() => this.handleControlButton('seekMinButton')}>
                          <i className='fas fa-fast-backward' />
                        </TooltipedButton>
                        <TooltipedButton text='-5m' onClick={() => this.handleControlButton('fastBackwardButton')}>
                          <i className='fas fa-backward' />
                        </TooltipedButton>
                        <TooltipedButton text='-30s' onClick={() => this.handleControlButton('backwardButton')}>
                          <i className='fas fa-caret-left' />
                        </TooltipedButton>
                        <TooltipedButton text='Play / Pause' onClick={() => this.handleControlButton('playButton')}>
                          <i className={this.state.playerState === 'PLAYING' ? 'fas fa-pause' : 'fas fa-play'} />
                        </TooltipedButton>
                        <TooltipedButton text='+30s' onClick={() => this.handleControlButton('forwardButton')}>
                          <i className='fas fa-caret-right' />
                        </TooltipedButton>
                        <TooltipedButton text='+5m' onClick={() => this.handleControlButton('fastForwardButton')}>
                          <i className='fas fa-forward' />
                        </TooltipedButton>
                        <TooltipedButton text='Live' onClick={() => this.handleControlButton('seekLiveButton')}>
                          <i className='fas fa-fast-forward' />
                        </TooltipedButton>
                      </ButtonGroup>
                      <ButtonGroup>
                        <TooltipedButton text='Mute / Unmute' variant='outline-dark' onClick={() => this.handleControlButton('Muting')}>
                          <i className={this.state.isMute ? 'fas fa-volume-mute' : 'fas fa-volume-up'} />
                        </TooltipedButton>
                        <TooltipedButton text='FullScreen' variant='outline-dark' onClick={() => ToggleFullScreen()}>
                          <i className='fas fa-expand' />
                        </TooltipedButton>
                      </ButtonGroup>
                    </Stack>
                  </div>
                </Stack>
              </Card.Footer>
            )}
          </Card>
        </Col>
        {this.state.isConfLoaded && (
          <Col>
            <Card border='dark'>
              <Card.Header>
                <h5 className='mb-0'>
                  <Stack direction='horizontal' gap='3'>
                    <Badge>{this.state.channelList.length} channels</Badge>
                    <BadgeCounter bg='success' nb={this.getNbChannelStatus('ok')} className='ms-auto'>
                      OK
                    </BadgeCounter>
                    <BadgeCounter bg='danger' nb={this.getNbChannelStatus('ko')}>
                      KO
                    </BadgeCounter>
                    <BadgeCounter bg='warning' nb={this.getNbChannelStatus('unknow')}>
                      Unknow
                    </BadgeCounter>
                  </Stack>
                </h5>
              </Card.Header>
              <Card.Body>
                <Stack gap='2'>
                  <InputComponent text='Zapping interval'>
                    <Form.Control type='number' value={this.state.zapTimerInterval} onChange={this.handleTimerChange} />
                    <TooltipedButton text='autoZap On\Off' onClick={this.handleAutoZapButton}>
                      <i className={autoZapButtonStyle[this.state.autozap]} />
                    </TooltipedButton>
                  </InputComponent>
                  <ProgressBar
                    variant='danger'
                    striped
                    animated
                    now={(100 * this.state.timerCounter) / this.state.zapTimerInterval}
                    label={`${this.state.timerCounter}s before zapping`}
                  />
                  <ChannelListComponent
                    channels={this.state.channelList}
                    currentIndex={this.state.assetIndex}
                    callback={this.onChannelClick}
                  />
                </Stack>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    );
  }
}

export default MonitoringPlayer;
